// LinkResolver.js file

exports.linkResolver = (doc) => {
    if (doc.type === 'page') {
        console.log('doc', doc)

        if (doc.uid === 'home') {
            return '/'
        } else {
            return `/${doc.uid}`
        }   
    }
    // if (doc.type === 'post') {
    //     return `/posts/${doc.uid}`
    // }


    return '/'
}